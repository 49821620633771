import styled from "styled-components";

export const Footer = styled.div`
  display: flex;
  background: var(--primary);
  margin-top: 28px;
  flex-direction: column;
  padding: 30px;
  gap: 20px;
  align-items: start;
  justify-content: center;
  @media (min-width: 768px) {
    align-items: center;
    flex-direction: row;
  }
`;

export const FooterItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  jutify-content: center;
  align-items: center;

  @media (min-width: 768px) {
    width: 30% 
  }
`;
