import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const WhatsAppWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  border-radius: 50%;
  background: green;
  width: 50px;
  height: 50px;
  svg {
    width: 30px;
    height: 30px;
  }
  @media (min-width: 768px) {
    width: 75px;
    height: 75px;
    svg {
      width: 50px;
      height: 50px;
    }
  }
`;
