import { AccordionItemButton, AccordionItemPanel } from "react-accessible-accordion";
import styled from "styled-components";

export const StyledAccordionButton = styled(AccordionItemButton)<{color?: string}>`
  padding: 16px 16px 16px 16px;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
 
  div {
    display: flex;
    align-items: center;
  }
  div p {
    color: ${props => props.color ? props.color : 'var(--primary)'};
    font-family: 'Roboto', sans-serif !important;
    font-size: 22px !important;
    font-weight: 600 !important;
  }
`;

export const StyledAccordionPannel = styled(AccordionItemPanel)<{color?: string}>`
padding: 8px 16px 8px 16px;
  div p {
    font-family: 'Roboto', sans-serif !important;
    font-size: 20px !important;
    font-weight: 400 !important;
  }
`;

export const Divider = styled.hr`
width: 100%;
`

export const Wrapper = styled.div`
    margin-top: 64px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    justify-content: center;
    text-align: center;

    div {
        width: 100%;
        text-align: start;
    }

    @media (min-width: 1024px) {
        flex-direction: row;
        width: 80%;
        align-self: center;
    }

`;

