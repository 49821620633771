import * as S from "./Info.styles";
import { BodyText } from "../../components/BodyText/BodyText";
import { Button } from "../../components/Button/Button";
import { MdWhatsapp } from "react-icons/md";

export const Info = () => {
  return (
    <S.InfoContainerWrapper id="info">
      <S.InfoWrapper>
        <S.InfoHeaderWrapper>
          <h2>Como funciona a terapia?</h2>
        </S.InfoHeaderWrapper>
        <S.InfoBodyWrapper>
          <BodyText color="#2d303f">
            <p>
              Você já se viu preso em um ciclo interminável de preocupações,
              ansiedade ou tristeza? Às vezes, a vida pode nos lançar desafios que
              parecem insuperáveis. É aí que a terapia entra em cena, como uma
              bússola confiável que te auxilia em meio à tempestade.
              <br />
              <br />
              Imagine finalmente sentir-se livre do peso que tem segurado seus
              ombros. Visualize uma mente clara e tranquila, pronta para enfrentar
              qualquer obstáculo que a vida apresente. A terapia funciona para
              diversos tipos de problemas, como: separações, ansiedade, luto,
              estresse, procrastinação, depressão....
              <br />
              <br />
              Ao embarcar nessa jornada, você não estará sozinho. Vou te auxiliar
              a navegar pelas águas turbulentas dos seus pensamentos e emoções. E
              vamos juntos criar estratégias para lidar com as adversidades.
              <br />
              <br />
              Não deixe que a angústia e o sofrimento dominem sua vida. Dê o
              primeiro passo em direção a uma existência mais plena, significativa
              e leve. A terapia está esperando por você! Permita-se a experiência
              transformadora da terapia hoje mesmo.
            </p>
          </BodyText>
          <Button text="Dê o primeiro passo" icon={<MdWhatsapp size={28} />} color="#60644d" hoverColor="#888e6d" />
        </S.InfoBodyWrapper>
      </S.InfoWrapper>
    </S.InfoContainerWrapper>
  );
};
