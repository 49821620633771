import styled from "styled-components";

export const StyledArrowWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-direction: column;
    
    &:hover {
        cursor: pointer;
    }

    @media (min-width: 768px) {
        margin-top: 40px;
    }
`;