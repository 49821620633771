import styled from "styled-components";

export const StyledSubTitleText = styled.h2`
    font-size: 20px;
    font-weight: 900;
    color: var(--primary);
    margin-bottom: 1.25rem;
    font-family: colabero;
    @media (min-width: 425px) {
        font-size: 24px;
    }
    @media (min-width: 720px) {
        padding: 16px;
        font-size: 24px;
       
    }
    @media (min-width: 1024px) {
        padding: 16px;
        font-size: 32px;
    }
`;