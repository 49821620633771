import React from 'react';
import * as S from './Button.styles';

type ButtonProps = {
    text?: string;
    color?: string;
    hoverColor?: string;
    icon?: React.JSX.Element;
    lowerIcon?: React.JSX.Element;
}

export const Button = ({text, color, hoverColor, icon, lowerIcon}: ButtonProps) => {
    return (
        <S.Button target="_blank" href={lowerIcon ? undefined : "https://wa.link/netv6h"}  color={color} hoverColor={hoverColor} lowerIcon={lowerIcon}>
            {icon}
            <p>{text ? text : 'Agende sua consulta'}</p>
            {lowerIcon}
        </S.Button>
    );
};
