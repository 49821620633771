import { IoIosArrowDown } from 'react-icons/io';
import { StyledArrowWrapper } from './SeeMore.styles';
import { Button } from '../Button/Button';

type SeeMoreProps = {
    element: string;
    color?: string;
};

export const SeeMore = ({element}: SeeMoreProps) => (
    <StyledArrowWrapper  onClick={() => {
        document
            .getElementById(element)
            ?.scrollIntoView({ behavior: "smooth" });
    }}>
        <Button 
            text=' ' 
            color='var(--primary)'
            hoverColor='#80310c'
            lowerIcon={ 
            <IoIosArrowDown
                style={{ marginTop: '-5px' }}
                size={20}
                color={"white"}/>
            }    
        />
        
    </StyledArrowWrapper>
);

