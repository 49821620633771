import styled, { keyframes } from "styled-components";

const fadeIn = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;
export const AboutMeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
  position: relative;
  justify-content: space-between;
  gap: 8px;

  @media (min-width: 768px) {
    flex-direction: row;
    padding: 50px 75px 12px 75px;
    gap: 25px;
  }
  @media (min-width: 1260px) {
    flex-direction: row;
    padding: 75px 75px 37px 75px;
    gap: 50px;
  }
`;

export const AboutMeImage = styled.img`
  border-radius: 5px;
  width: 242px;
  height: 364px;
  align-self: center;
  margin-top: 50px;
  @media (min-width: 768px) {
    align-self: start;
    margin-top: 0px;
    width: 380px;
    height: 500px;
  }
`;

export const AboutMeTopDivider = styled.div`
  position: absolute;
  width: 100px;
  height: 100px;
  border: solid 1px var(--primary);
  border-right: none;
  border-bottom: none;
  top: 15px;
  left: 5vw;

  @media (min-width: 768px) {
    flex-direction: row;
    margin-top: 24px;
    padding: 50px 75px 12px 75px;
    gap: 25px;
    left: 15px;
  }
`;

export const AboutMeBottomDivider = styled.div`
  bottom: 15px;
  right: 15px;
  position: absolute;
  width: 100px;
  height: 100px;
  border: solid 1px var(--primary);
  border-top: none;
  border-left: none;
`;

export const AboutMeTextWrapper = styled.div<{ applyanimation: string }>`
  display: flex;
  flex-direction: column;
  padding: 50px;
  animation: ${(props) => (props.applyanimation === "true" ? fadeIn : "none")}
    1s;
  q {
    font-weight: 300;
    font-size: 16px;
  }
  @media (min-width: 1024px) {
    padding: 16px;
    q {
      font-size: 20px;
    }
  }
`;


