import * as S from "./Header.styles";
import logo from "../../images/logo_1.webp";

export const Header = () => {
  const scrollToElement = (elementId: string) => {
    const element = document.getElementById(elementId);

    element?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <S.HeaderWrapper>
      <S.HeaderImage
        src={logo}
        alt="Aurea Logo"
        width={150}
        height={150}
        loading="eager"
      />
      <S.HeaderButtonsWrapper>
        <h4 onClick={() => scrollToElement("info")}>Como Funciona?</h4>
        <h4 onClick={() => scrollToElement("about-me")}>Sobre Mim</h4>
        <h4 onClick={() => scrollToElement("services")}>Serviços</h4>
        <h4 onClick={() => scrollToElement("FAQ")}>FAQ</h4>
      </S.HeaderButtonsWrapper>
    </S.HeaderWrapper>
  );
};
