import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
} from 'react-accessible-accordion';
import { BodyText } from '../../components/BodyText/BodyText';
import * as S from './FAQ.styles';
import {  MdChevronRight } from 'react-icons/md';
import { SubTitleText } from '../../components/SubTitleText/SubTitleText';

const FaqData = [
    {
        question: 'Atendo por plano de saúde?',
        answer: 'No momento só atendo de forma particular, mas você pode entrar em contato com seu plano para ver se consegue reembolso total ou parcial do valor das sessões.'
    },
    {
        question: 'Qual é o valor da sessão?',
        answer: 'Para saber mais informações sobre as consultas, incluindo valores, clique no botão de contato.'
    },
    {
        question: 'Eu realizo diagnóstico?',
        answer: 'Sim, de acordo o codigo de ética do Psicólogo Resolução CFP No 10/2005 todo psicólogo pode realizar diagnóstico baseados em conhecimentos científicos.'
    },
    {
        question: 'Como é feito o pagamento?',
        answer: 'O pagamento é feito de forma antecipada, podendo ser feito por pix, cartão (crédito ou débito) e paypal.'
    },
];

export const FAQ = () => {
    return (
        <S.Wrapper id="FAQ">
            <SubTitleText text="Perguntas Frequentes" />
            <Accordion allowZeroExpanded>
                {FaqData.map((faq, index) => (
                    <AccordionItem key={index}>
                        <AccordionItemHeading>
                        <S.StyledAccordionButton color={index%2 === 1 ? '#080B31' : undefined}>
                                <BodyText>
                                    <p> 
                                        {faq.question}
                                    </p>
                                </BodyText>
                                <MdChevronRight color="var(--primary)" size={30}/>
                                </S.StyledAccordionButton>
                        </AccordionItemHeading>
                        <S.StyledAccordionPannel>
                            <BodyText>
                                <p> 
                                    {faq.answer}
                                </p>
                            </BodyText>
                            </S.StyledAccordionPannel>
                        <S.Divider />
                    </AccordionItem>
                ))}
        </Accordion>
    </S.Wrapper>
    );
};
