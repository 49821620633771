import styled from "styled-components";

export const Button = styled.a<{color?: string, hoverColor?: string, lowerIcon?: React.JSX.Element}>`
  position: static;
  background-color: ${ props => props.color ? props.color : '#60644d'};
  color: #60644d;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  color: white;
  padding: 0px;
  padding-top: ${ props => props.lowerIcon ? '5px' : '0px;'};
  width: ${ props => props.lowerIcon ? '75px' : '200px;'};
  border-radius: ${ props => props.lowerIcon ? '25px' : '5px;'};
  height: 40px;
  font-size: 16px;
  font-weight: 400;
  gap: ${ props => props.lowerIcon ? '0px' : '10px;'};
  margin-top: 20px;
  flex-direction: ${ props => props.lowerIcon ? 'column' : 'row'};

  p {
    font-size: 15px;
    font-weight: 400;
    font-family: "Roboto";
  }

  &:hover {
    cursor: pointer;
    background-color:  ${ props => props.hoverColor ? props.hoverColor : '#888e6d'};
    box-shadow: 2px 2px 5px white;
  }
  @media (min-width: 768px) {
    width: ${ props => props.color === 'var(--primary)' ? '100px': '250px'};
    height: 50px;
    font-size: 24px;
    gap:  ${ props => props.lowerIcon ? '0px' : '10px'};
    margin-top: 16px;
    p {
      font-size: 20px;
      font-weight: 400;
      font-family: "Roboto";
    }
    svg {
      width: 30px;
      height: 30px;
    }
  }
`;