import styled from "styled-components";

export const StyledBodyText = styled.div<{color?: string}>`
    p {
        font-size: 18px ;
        font-weight: 400 ;
        color: ${props => props.color ? props.color : 'var(--primary)'};
        font-family: "corporative", sans-serif;
    }
    
    @media (min-width: 425px) {
        p {
            font-size: 20px ;
        }
    }
    @media (min-width: 720px) {
         p {
            font-size: 24px ;
        }
    }
`;