import { StyledBodyText } from './BodyText.styles';

interface BodyTextProps {
    children: React.JSX.Element;
    color?: string;
}

export const BodyText = ({ children, color }: BodyTextProps) => {
    return <StyledBodyText color={color}>{children}</StyledBodyText>;
};
