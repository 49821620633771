
import * as S from "./Footer.styles";

import { BannerBody, PhoneWrapper } from "../../section/Banner/Banner.styles";
import { HiOutlinePhone } from "react-icons/hi";
import { FiInstagram } from "react-icons/fi";
import { MdOutlineMail } from "react-icons/md";
import { MdOutlinePlace } from "react-icons/md";
import { TbTrident } from "react-icons/tb";

export const Footer = () => {
    return (
    <S.Footer>
        <S.FooterItem>
          <PhoneWrapper>
            <TbTrident />
            <BannerBody>CRP: 15/7553</BannerBody>
          </PhoneWrapper>
          <PhoneWrapper>
            <MdOutlineMail />
            <BannerBody>aureapsicologamcz@gmail.com</BannerBody>
          </PhoneWrapper>
        </S.FooterItem>
        <S.FooterItem>
          <PhoneWrapper>
            <HiOutlinePhone />
            <BannerBody>(82)993416300</BannerBody>
          </PhoneWrapper>
          <PhoneWrapper>
            <FiInstagram />
            <BannerBody>psi.aureagomes</BannerBody>
          </PhoneWrapper>
        </S.FooterItem>
        <S.FooterItem>
          <PhoneWrapper>
            <MdOutlinePlace />
            <BannerBody>
              Edf. Premium Office, Av. Gov. Osman Loureiro, 3506 -
              Mangabeiras, Maceió - AL, 57037-630
            </BannerBody>
          </PhoneWrapper>
          <PhoneWrapper>
            <MdOutlinePlace />
            <BannerBody>
              Clínica Enova, Eng°. Otávio Cabral, 363 - Gruta de Lourdes,
              Maceió - AL, 57052-483
            </BannerBody>
          </PhoneWrapper>
        </S.FooterItem>
      </S.Footer>
    );
};
